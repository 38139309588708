<script>
export default {
	props: {
		order: Object,
		creditCardForm: Object,
		data: Object,
	},

	mounted() {
		let action = this.data.action
		let inputs = {
			...this.data.inputs,
			cardnumber: this.creditCardForm.number,
			expmonth: this.creditCardForm.expirationMonth,
			expyear: this.creditCardForm.expirationYear,
			cvm: this.creditCardForm.cvv,
		}
		var iframe = document.createElement('iframe')
		iframe.onload = function () {
			iframe.contentWindow.postMessage({ inputs, action })
		}
		iframe.width = '0'
		iframe.height = '0'
		iframe.style.display = 'none'
		this.$nextTick(() => {
			let { baseUrl } = this.$shopApi.defaults()
			iframe.src = baseUrl + '/credit-card/first-data-form'
			this.$el.appendChild(iframe)
		})
		window.addEventListener('message', (event) => {
			if (event.data.approved) {
				this.$emit('approved')
			} else if (event.data.invalid) {
				this.$emit('invalid')
			} else {
				this.$emit('rejected', { rejectionCode: event.data.rejectionCode })
			}
			iframe.remove()
		})
	},
}
</script>

<template>
	<div></div>
</template>
